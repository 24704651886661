import {Component} from '@angular/core';

@Component({
  selector: 'app-terms-and-condition',
  templateUrl: './terms-and-condition.html',
  styleUrls: ['./terms-and-condition.css']
})
export class TermsAndConditionComponent {

}
